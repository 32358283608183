import 'keen-slider/keen-slider.min.css';
import { useState } from 'react';

import Image from 'next/image';

import { Container, Section } from 'components/homepage';

import type { Advisors } from 'types';
import { buildUrl } from 'util/loadContent';

import HarvardLogo from 'public/assets/home-page/advisors/HarvardLogo-2.png';
import JeffersonLogo from 'public/assets/home-page/advisors/JeffersonLogo-2.png';
import StanfordLogo from 'public/assets/home-page/advisors/StanfordLogo-2.png';
import UCSFLogo from 'public/assets/home-page/advisors/UCSFLogo-2.png';
import YaleLogo from 'public/assets/home-page/advisors/YaleLogo-2.png';

/**
 * Our advisors are a huge asset. They give us credibility and allow us to make the claim that we're the most trusted
 * brand in the space. We need to make this visible. This section is a huge brand differentiator. Important things
 * that this section should do:
 * 1. Show all the faces. Someone should look at our list of advisors and say, "Whoa, they have everyone. They're legit"
 * 2. Ideally show a clear association between the advisor and Levels. Maybe a quote (or better yet, a video) of them
 * saying something specifically about Levels to show that they're involved.
 */

// Our advisors are a huge asset and we're hiding it for some reason...
// this slider is bad
// show all their faces
// bios also don't matter - nobody is going to "discover" an advisor here
// people look at the advisors list to go "oh wow, I know those people, I trust them"

const sectionTitle = 'Medical & Scientific Advisors';
const schools = [StanfordLogo, UCSFLogo, JeffersonLogo, YaleLogo, HarvardLogo];
const list = [
  // selected advisors to be displayed first
  {
    bio: 'Founder & Director of The UltraWellness Center; Author of 14 New York Times best selling books; Host of The Doctor’s Farmacy – a leading podcast on Health & Wellness',
    degree: 'MD',
    link: 'https://drhyman.com/levels/',
    image: buildUrl('AdvisorHyman.png', 'webp'),
    name: 'Mark Hyman',
  },
  {
    bio: 'Professor of Genetics and co-Director of the Paul F. Glenn Center for Biology of Aging Research, Harvard Medical School; Author of Lifespan; Listed as one of TIME Magazine’s “100 most influential people in the world”',
    degree: 'PhD',
    link: 'https://www.youtube.com/watch?v=VL1SQNH6PSY&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=5',
    image: buildUrl('AdvisorSinclair.png', 'webp'),
    quote:
      'Keeping blood glucose stable is an essential piece of my longevity protocol. Everyone is unique, and I’ve been able to learn what foods do and don’t impact my blood sugar by using Levels. For example, white rice and grapes really spike my glucose, whereas potatoes don’t. Real-time visibility into your body is critical for understanding and optimizing your health.',
    name: 'David A. Sinclair',
  },
  {
    bio: 'Assistant Professor of Integrative Medicine & Nutritional Sciences, Thomas Jefferson University; Author of three New York Times best selling books, including: The Hormone Cure, The Hormone Reset Diet, and Younger',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=QZUpJCNOpfY',
    image: buildUrl('AdvisorGottfried.png', 'webp'),
    name: 'Sara Gottfried',
  },
  {
    bio: 'Associate Professor of Molecular Pharmacology and Physiology, University of South Florida Morsani College of Medicine; Founder of ketonutrition.org',
    degree: 'PhD',
    link: 'https://www.youtube.com/watch?v=GKtf87JXlTA&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=1&pp=iAQB',
    image: buildUrl('AdvisorDAgostino.png', 'webp'),
    name: 'Dominic D’Agostino',
  },
  // other advisors (alphabetical)
  {
    bio: 'Author of Why We Get Sick; Associate Professor of Physiology and Developmental Biology, Brigham Young University',
    degree: 'PhD',
    link: 'https://www.youtube.com/watch?v=szGl8F1zl6I',
    image: buildUrl('AdvisorBikman.png', 'webp'),
    name: 'Benjamin Bikman',
  },
  {
    bio: 'Professor Emeritus of Pediatrics, Division of Endocrinology at the University of California, San Francisco; Author of five books, including New York Times best seller Fat Chance and the recent release Metabolical',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=gWHIOua2yGA&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=6&pp=iAQB',
    image: buildUrl('AdvisorLustig.png', 'webp'),
    name: 'Robert Lustig',
  },
  {
    bio: 'Lecturer within the Wellness Department of the Medical School at Stanford University; Advisor to more than 40 companies in digital health, consumer health, and biotechnology',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=t2asIt8md-I&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=3',
    image: buildUrl('AdvisorMaloof.png', 'webp'),
    name: 'Molly Maloof',
  },
  {
    bio: 'Board-Certified Neurologist; Author of six New York Times bestselling books, including: Drop Acid, Grain Brain, The Surprising Truth About Wheat, Carbs and Sugar, among others',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=ihvdIFb4YHo&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=8',
    image: buildUrl('AdvisorPerlmutter.png', 'webp'),
    name: 'David Perlmutter',
  },
  {
    bio: 'Professor of Medicine and Cellular & Molecular Physiology at Yale; Banting Medal for Lifetime Scientific Achievement from the American Diabetes Association',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=2TgNZs5mkNc&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=8&pp=iAQB',
    image: buildUrl('AdvisorShulman.png', 'webp'),
    name: 'Gerald Shulman',
  },
  {
    bio: 'Clinical Professor of Medicine, University of Iowa; Linus Pauling Award recipient (2018); Author of The Wahls Protocol, and The Wahls Protocol Cooking for Life; Founder of The Wahls Lab',
    degree: 'MD',
    link: 'https://www.youtube.com/watch?v=tgu_QaYQnUw&list=PLt_J9-Sfer-mTtOKagtYRUni4d2FFOuIv&index=4&pp=iAQB',
    image: buildUrl('AdvisorWhals.png', 'webp'),
    name: 'Terry Wahls',
  },
];

/**
 * @summary Renders a carousel with advisors information.
 * @returns {JSX.Element}
 */

const Advisors = ({ showSchools = true }): JSX.Element => {
  // if this is true, advisor toggle is expanded
  const [advisorExpandIsOpen, setAdvisorExpandState] = useState(false);

  // TODO at some point figure out how to get this height computed with javascript so we can animate it
  return (
    <Section className="py-10 md:py-20" style={{ backgroundColor: 'rgb(15, 15, 15)' }}>
      <style jsx>{`
        .advisor-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          overflow: hidden;
          transition: height 0.3s ease;
          ${advisorExpandIsOpen ? 'height: auto' : 'height: 400px;'}
        }
        .advisor-item {
          height: 200px;
          width: 200px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0px 5px;
        }
        .advisor-image-container {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
        }
        .title {
          text-align: center;
          font-size: 2em;
          padding-bottom: 30px;
        }
        .show-all-container {
          display: none;
          flex-direction: column;
          align-items: center;
          position: absolute;
          bottom: -40px;
          left: 0;
          right: 0;
          cursor: pointer;
        }
        .show-all-container:hover {
          color: rgb(150, 150, 150);
        }
        .show-all-container:hover > .triangle {
          border-top: 10px solid rgb(150, 150, 150);
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid white;
          transition: border-top 0.3s ease;
        }
        .show-all-title {
          font-size: 1.2em;
          font-weight: 300;
          padding-bottom: -5px;
          transition: color 0.3s ease;
        }
        .show-all-title:hover {
          color: rgb(150, 150, 150);
        }
        @media (max-width: 1300px) {
          .show-all-container {
            display: flex;
          }
          .advisor-list {
          }
          .advisor-item {
          }
        }
        @media (max-width: 900px) {
          .title {
            max-width: 420px;
          }
          .advisor-item {
            height: 150px;
            width: 150px;
          }
          .advisor-image-container {
            height: 120px;
            width: 120px;
          }
          .advisor-list {
            ${advisorExpandIsOpen ? 'height: auto' : 'height: 300px;'}
          }
        }
      `}</style>
      <div className="px-10" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <p className="text-dark-green font-semibold uppercase tracking-wider mb-6 md:mb-8">{sectionTitle}</p>
        <div className="title">Trusted by the biggest names in metabolic health</div>
      </div>

      <Container>
        <div style={{ position: 'relative' }}>
          <div className="advisor-list">
            {list.map((item, index) => (
              <a href={item.link} target="_blank" rel="noreferrer" key={index} className="advisor-item">
                <div className="advisor-image-container">
                  <Image layout="fill" objectFit="cover" objectPosition="center top" src={item.image} />
                </div>
                <div style={{ paddingTop: 10 }}>{item.name}</div>
              </a>
            ))}
          </div>
          <div className="show-all-container" onClick={() => setAdvisorExpandState(!advisorExpandIsOpen)}>
            <div className="show-all-title">{advisorExpandIsOpen ? 'Close' : 'Show all'}</div>
            {advisorExpandIsOpen ? <></> : <div className="triangle" />}
          </div>
        </div>
      </Container>

      {showSchools && (
        <Container>
          <div className="flex flex-wrap gap-x-8 justify-center mt-20">
            {schools.map((item, index) => (
              <div style={{ width: 130, height: 80, position: 'relative', padding: '0 10px' }} key={`${index}-logo`}>
                <Image layout="fill" objectFit="scale-down" src={item} />
              </div>
            ))}
          </div>
        </Container>
      )}
    </Section>
  );
};

export default Advisors;

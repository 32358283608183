import { FC } from 'react';

import cx from 'classnames';
import { isDefined, isTruthy } from 'util/parseUtils';

export type SectionColor = 'white' | 'light-gray' | 'gray' | 'black';

type SectionProps = {
  center?: boolean;
  children: React.ReactNode;
  className?: string;
  color?: SectionColor;
  fullHeight?: boolean;
  style?: React.CSSProperties;
};

export const Section: FC<SectionProps> = ({ color, children, className, center, fullHeight, style }) => (
  <section
    style={style}
    className={cx(
      'w-full',
      {
        // background-color
        'bg-black-title': color === 'black',
        'bg-gray-background': color === 'gray',
        'bg-light-gray-bg': color === 'light-gray',
        // text color
        'text-white': !isDefined(color) || color === 'gray' || color === 'black',
        'text-black-title': color === 'white' || color === 'light-gray',
        //
        'h-full': isTruthy(fullHeight),
        'text-center': center,
      },
      className
    )}
  >
    {children}
  </section>
);

export const Container: FC<{ className?: string }> = ({ children, className }) => (
  <div className={cx('mx-auto max-w-[1440px] w-full justify-center px-6 md:px-10 lg:px-12.5 xl:px-14 2xl:px-16 3xl:px-20', className)}>
    {children}
  </div>
);

export const SubHeading: FC<{
  Component?: React.ElementType;
  className?: string;
  style?: Object;
}> = ({ children, Component = 'p', className, style }) => (
  <Component style={style} className={cx('text-lg text', className)}>
    {children}
  </Component>
);
